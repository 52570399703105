/* eslint-disable */
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
	n.callMethod.apply(n,arguments):n.queue.push(arguments)};
	if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
	n.queue=[];t=b.createElement(e);t.async=!0;
	t.src=v;s=b.getElementsByTagName(e)[0];
	s.parentNode.insertBefore(t,s)}(window,document,'script',
	'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '374210183863908');
fbq('track', 'PageView');

const fbNoscript = document.createElement('noscript');
const fbImg = document.createElement('img');
fbImg.setAttribute('height', '1');
fbImg.setAttribute('width', '1');
fbImg.setAttribute('src', 'https://www.facebook.com/tr?id=374210183863908&ev=PageView&noscript=1');
fbNoscript.appendChild(fbImg);
document.body.appendChild(fbNoscript);

/* Pixel counter */
const script = document.createElement('script');
const noscript = document.createElement('noscript');
const pixel = document.createElement('img');

script.setAttribute('src', '//vk.com/js/api/openapi.js?169');
script.onload = () => {
	VK.Retargeting.Init('VK-RTRG-1722953-1hBhw')
	VK.Retargeting.Hit();
};
script.async = true;

pixel.src = 'https://vk.com/rtrg?p=VK-RTRG-1722953-1hBhw'
pixel.style.cssText = `
	position: fixed;
	left: -999px;
`;
pixel.alt = '';

noscript.appendChild(pixel);

document.head.appendChild(script);
document.body.appendChild(noscript);


/* Mail counter */
const MailScript = document.createElement('script');
const MailNoscript = document.createElement('noscript');

MailScript.setAttribute('type', 'text/javascript');
MailScript.textContent = `
var _tmr = window._tmr || (window._tmr = []);
_tmr.push({id: "3321340", type: "pageView", start: (new Date()).getTime()});
(function (d, w, id) {
	if (d.getElementById(id)) return;
	var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
	ts.src = "https://top-fwz1.mail.ru/js/code.js";
	var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
	if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
})(document, window, "tmr-code")`;

document.head.appendChild(MailScript);

MailNoscript.innerHTML = `<div>
	<img src="https://top-fwz1.mail.ru/counter?id=3321340;js=na" style="position:absolute;left:-9999px;" alt="Top.Mail.Ru" />
</div>`;

document.body.appendChild(MailNoscript);